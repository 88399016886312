import React from "react";
import NavBar from "../sections/NavBar";
import Footer from "../sections/Footer";
import Contact from "../sections/Contact";
import Quote from "../sections/Quote";
import Dinner from "../sections/menus/Dinner";
import MenuMessage from "../sections/MenuMessage";

const DinnerMenu = () => (
  <>
    <NavBar />
    <MenuMessage />
    <Dinner />
    {/* <Quote /> */}
    <Contact />
    <Footer />
  </>
);

export default DinnerMenu;
