import React from "react";
import NavBar from "../sections/NavBar";
import Footer from "../sections/Footer";
import HorsDoeuvres from "../sections/menus/HorsDoeuvres";
import Contact from "../sections/Contact";
import Quote from "../sections/Quote";
import MenuMessage from "../sections/MenuMessage";

const HorsDoeuvresMenu = () => (
  <>
    <NavBar />
    <MenuMessage />
    <HorsDoeuvres />
    {/* <Quote /> */}
    <Contact />
    <Footer />
  </>
);

export default HorsDoeuvresMenu;
